import React, { Component } from "react";
import manageStorageProfileStyles from "./manage-storage-profile.module.scss";

import inputStyles from "../../shared-ui/styles/input.module.scss";
import AllottedSpace from "./allottedSpace";
import Button from "../../shared-ui/button";
import AddUsers from "../manage-users/addUsers/index";
import { connect } from "react-redux";
import { IUser } from "../../../api/entities/user";
import { IAppState } from "../../../redux/reducers";
import NotificationsSection from "./notifications-section";
import Footer from "../footer/footer";
import {
  getRetentionSettingsForPolicy,
  isRetentionSettingsValid,
  RetentionSection,
  IRetentionSettings,
  IRetentionSettingsErrors,
} from "./retention-section/retention-section";
import { IStorageProfileContainerState } from "./storage-profile-container-state";
import { IStorageProfileContainerError } from "./storage-profile-container-error";
import { IPolicyAdd, LogoutTimer } from "../../../api/entities/policy";
import { userService } from "../../../api/services/user-service";
import { catchException, commonActions } from "../../../redux/actions/common";
import Checkbox from "../../shared-ui/input/checkbox";
import { WizardProgressBar } from "../../shared-ui/wizard-progress-bar/wizard-progress-bar";
import SidePanelSection from "../../shared-ui/side-panel-section/side-panel-section";
import { OfflineFilesSection } from "./offline-files-section/offline-files-section";
import _ from "underscore";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { STORAGE_PROFILES_ROUTE } from "../../../constants/routes";
import { storageProfileThunkActions } from "../../../redux/actions/storage-profile";
import { PoolSection } from "./pool-section/pool-section";
import { ChangePoolConfirmation, ChangePoolReason } from "../../storage-pools/dialogs/change-pool-confirmation/change-pool-confirmation";

enum Step {
  StoragePool = 0,
  AllottedSpace = 1,
  RetentionPolicy = 2,
  Notifications = 3,
  OfflineFiles = 4,
  Users = 5,
  ProfileName = 6
}

interface IAddStorageProfileContainerProps
  extends RouteComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
      createDefaultProfile?: boolean;
      isFirstProfile?: boolean;
}

interface IAddStorageProfileContainerState extends IStorageProfileContainerState {
  step: Step;
}

class AddStorageProfileContainer extends Component<
  IAddStorageProfileContainerProps,
  IAddStorageProfileContainerState
> {
  state: IAddStorageProfileContainerState = {
    storagePoolId: null,
    allottedSpace: 0,
    step: Step.StoragePool,
    shouldnotify: false,
    allowOfflineFiles: false,
    localAuthentication: false,
    logoutTimer: LogoutTimer.Minutes15,
    addedUserArr: [],
    notifyamount: null,
    storageProfileName: "",
    defaultStorageProfile: false,
    deletedFilesMode: null,
    isDeletedFilesMbEnabled: true,
    isDeletedFilesDaysEnabled: true,
    deletedFilesDays: null,
    deletedFilesMb: null,
    revisionsMode: null,
    isRevisionsMbEnabled: true,
    isRevisionsDaysEnabled: true,
    revisionsDays: null,
    revisionsMb: null,
    error: {
      retentionSettings: {},
    } as IStorageProfileContainerError,
    isChangePoolConfirmationOpen: false
  };

  handleAllottedSpace = value => {
    this.setState({ allottedSpace: value });
  };
  onRetentionChange = (retention: Partial<IRetentionSettings>) => {
    this.setState({ ...this.state, ...retention });
  };
  onRetentionError = (errors: Partial<IRetentionSettingsErrors>) => {
    this.setState({
      error: {
        retentionSettings: {
          ...this.state.error.retentionSettings,
          ...errors,
        },
      },
    });
  };
  handleNext = () => {
    let nextStep = this.state.step + 1;
    if (this.props.isFirstProfile && nextStep === Step.Users) {
      nextStep = nextStep + 1;
    }
    this.setState({ step: nextStep });
  };
  handleCheckBoxProfile = defaultStorageProfile => {
    this.setState({ defaultStorageProfile });
  };
  handleNotification = selection => {
    this.setState({ shouldnotify: selection });
  };
  handlePrevious = () => {
    this.setState({ step: this.state.step - 1 });
  };
  handleAddUsers = (selected: IUser[]) => {
    if (selected.length) {
      this.setState({
        addedUserArr: _.sortBy(this.state.addedUserArr.concat(selected), user => user.email),
      });
    }
  };
  handleRemoveUser = (selected: IUser[]) => {
    if (selected.length) {
      const selectedIDs = selected.map(u => u.id),
        res = this.state.addedUserArr.filter(
          addedUser => !selectedIDs.includes(addedUser.id)
        );
      this.setState({ addedUserArr: res });
    }
  };
  saveWithChangePoolConfirmation = () => {
    if (!this.props.isFirstProfile && this.state.addedUserArr.some(user => user.Policy.StoragePool.id !== this.state.storagePoolId)) {
      this.setState({ isChangePoolConfirmationOpen: true});
    }
    else {
      this.handleSave();
    }
  };
  handleSave = () => {
    const addRequest: IPolicyAdd = {
      name: this.state.storageProfileName,
      storagePoolId: this.state.storagePoolId,
      spaceLimitMegabytes: this.state.allottedSpace,
      shouldnotify: this.state.shouldnotify,
      allowOfflineFiles: this.state.allowOfflineFiles,
      logoutTimer: this.state.logoutTimer,
      localAuthentication: this.state.localAuthentication,
      notifyamount: this.state.notifyamount,
      selectedUsers: this.state.addedUserArr.map(ele => ele.id),
      default: this.state.defaultStorageProfile || this.props.createDefaultProfile,
      ...getRetentionSettingsForPolicy(this.state),
    };

    if (this.props.isFirstProfile) {
      this.props.startLoading();
      userService.getPage({}).then(usersData => {
        addRequest.selectedUsers = usersData.data.map(user => user.id);
        this.props.addNewStorageProfileProfile(addRequest).then(this.handleSaveSuccess);
      }).catch(catchException()).finally(this.props.stopLoading);
    }
    else {
      this.props.addNewStorageProfileProfile(addRequest).then(this.handleSaveSuccess);
    }
  };
  handleSaveSuccess = () => {
    this.props.history.push({ pathname: STORAGE_PROFILES_ROUTE, search: this.props.location.search });
  }

  renderPreviousButton() {
    return (
      <Button testId="back-button" colors="link" onClick={this.handlePrevious}>
        Previous
      </Button>
    );
  }

  render() {
    return (
      <div className={manageStorageProfileStyles["add-container"]}>
        <WizardProgressBar
          className={manageStorageProfileStyles.progress}
          step={this.state.step}
          steps={[
            "Storage Pool",
            "Allotted Space",
            "Retention",
            "Notifications",
            "Offline Files",
            "Users"
          ]}
        />
        {this.state.step === Step.StoragePool && (
          <>
            <PoolSection
              autoFocus={true}
              storagePool={{id: this.state.storagePoolId, name: null}}
              onChange={(storagePool) => this.setState({ storagePoolId: storagePool.id })}
            />
            <Footer>
              <Button
                testId="next-button"
                colors="primary"
                disabled={!this.state.storagePoolId}
                onClick={this.handleNext}
              >
                Next: Allotted Space
              </Button>
            </Footer>
          </>
        )}
        {this.state.step === Step.AllottedSpace && (
          <>
            <AllottedSpace
              autoFocus={true}
              handleAllottedSpace={this.handleAllottedSpace}
              allottedSpace={this.state.allottedSpace}
            />
            <Footer>
              {this.renderPreviousButton()}
              <Button
                testId="next-button"
                colors="primary"
                disabled={this.state.allottedSpace === 0}
                onClick={this.handleNext}
              >
                Next: Retention Policy
              </Button>
            </Footer>
          </>
        )}
        {/* ---------------------storage Retention------------------- */}
        {this.state.step === Step.RetentionPolicy && (
          <>
            <RetentionSection
              autoFocus={true}              
              {...this.state}
              errors={this.state.error.retentionSettings}
              onError={error => this.onRetentionError(error)}
              onChange={value => this.onRetentionChange(value)}
            />
            <Footer>
              {this.renderPreviousButton()}
              <Button
                testId="next-button"
                colors="primary"
                disabled={
                  !isRetentionSettingsValid(this.state)
                }
                onClick={this.handleNext}
              >
                Next: Notifications
              </Button>
            </Footer>
          </>
        )}

        {/* ---------------------notification------------------ */}
        {this.state.step === Step.Notifications && (
          <>
            <NotificationsSection
              autoFocus={true}
              notify={this.state.shouldnotify}
              amount={this.state.notifyamount}
              onChange={this.handleNotification}
              onAmountChange={notifyamount => this.setState({ notifyamount })}
            />
            <Footer>
              {this.renderPreviousButton()}
              <Button
                testId="next-button"
                colors="primary"
                disabled={this.state.shouldnotify && !this.state.notifyamount}
                onClick={this.handleNext}
              >
                Next: Offline Files
              </Button>
            </Footer>
          </>
        )}
        {/* ---------------------Offline Files------------------ */}
        {this.state.step === Step.OfflineFiles && (
          <>
            <OfflineFilesSection
              autoFocus={true}
              allowOfflineFiles={this.state.allowOfflineFiles}
              localAuthentication={this.state.localAuthentication}
              logoutTimer={this.state.logoutTimer}
              onChange={(changes) => this.setState({...this.state, ...changes})}
            />
            <Footer>
              {this.renderPreviousButton()}
              <Button
                testId="next-button"
                colors="primary"
                onClick={this.handleNext}
              >
                {this.props.isFirstProfile ? "Next: Profile Name" : "Next: Users"}
              </Button>
            </Footer>
          </>
        )}

        {this.state.step === Step.Users && (
          <SidePanelSection title="Add Users">
            <AddUsers
              handleAddUsers={this.handleAddUsers}
              addedUserArr={this.state.addedUserArr}
              handleRemoveUser={this.handleRemoveUser}
              spaceLimitMb={this.state.allottedSpace}
            />
            <Footer>
              {this.renderPreviousButton()}
              <Button
                testId="next-button"
                colors="primary"
                onClick={this.handleNext}
              >
                Next: Profile Name
              </Button>
            </Footer>
          </SidePanelSection>
        )}
        {this.state.step === Step.ProfileName && (
          <>
            <SidePanelSection title="Name this Profile" testId="profile-name">
              <div
                className={manageStorageProfileStyles.question}
                data-testid="profile-name-label"
              >
                Storage Profile Name
              </div>
              <div style={{ marginBottom: 26 }}>
                <input
                  className={inputStyles.root}
                  value={this.state.storageProfileName}
                  data-testid="profile-name-field"
                  autoComplete="off"
                  autoFocus
                  onChange={e =>
                    this.setState({ storageProfileName: e.target.value })
                  }
                />
              </div>
              {!this.props.createDefaultProfile && (
              <div data-testid="make-default">
                <Checkbox
                  testId="make-default-checkbox"
                  checked={this.state.defaultStorageProfile}
                  label="Make default"
                  onChange={this.handleCheckBoxProfile}
                />
              </div>
              )}
            </SidePanelSection>
            <Footer>
              {this.renderPreviousButton()}
              <Button
                testId="save-button"
                colors="primary"
                disabled={this.state.storageProfileName === ""}
                onClick={() => {
                  this.saveWithChangePoolConfirmation();
                }}
              >
                Save Profile
              </Button>
            </Footer>
          </>
        )}
        <ChangePoolConfirmation 
          isOpen={this.state.isChangePoolConfirmationOpen}
          changePoolReason={ChangePoolReason.CreateNewProfile}
          onClose={() => this.setState({ isChangePoolConfirmationOpen: false })}
          onConfirm={this.handleSave}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({});
const mapDispatchToProps = dispatch => {
  return {
    addNewStorageProfileProfile: (data: IPolicyAdd) => {
      return dispatch(storageProfileThunkActions.addNewStorageProfileProfile(data));
    },
    startLoading: () => {
      dispatch(commonActions.startLoading());
    },
    stopLoading: () => {
      dispatch(commonActions.stopLoading());
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddStorageProfileContainer));
