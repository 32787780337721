import { ISharingInstanceBase, ISharingInstanceShort } from "../api/entities/sharing-instance";
import { dataMigrationUtils } from "./data-migration-utils";

class SharingInstanceUtils {
    getFolderName(sharingInstance: ISharingInstanceBase): string {
        if (sharingInstance.deletedAt) {
            const delimmiterIndex = sharingInstance.folderName.indexOf("?dt=");
            if (delimmiterIndex > 0) {
                return sharingInstance.folderName.substring(0, delimmiterIndex);
            }
        }
        return sharingInstance.folderName;
    }

    canSelectSharingInstance(sharingInstance: ISharingInstanceBase): boolean {
        return !dataMigrationUtils.hasActiveMigrations(sharingInstance); 
    }

    canEditSharingInstance(sharingInstance: ISharingInstanceBase): boolean {
        return !dataMigrationUtils.hasActiveMigrations(sharingInstance); 
    }

    canManageSharingInstance(sharingInstance: ISharingInstanceBase): boolean {
        return !dataMigrationUtils.hasActiveMigrations(sharingInstance); 
    }

    canRestoreFiles(sharingInstance: ISharingInstanceBase): boolean {
        return !this.isDeleted(sharingInstance);
    }

    canManageUsers(sharingInstance: ISharingInstanceShort): boolean {
        return !this.isDeleted(sharingInstance);
    }

    canDelete(sharingInstance: ISharingInstanceShort): boolean {
        return !this.isDeleted(sharingInstance);
    }
    
    isDeleted(sharingInstance: ISharingInstanceBase): boolean {
        return !!sharingInstance.deletedAt;
    }
}

export const sharingInstanceUtils = new SharingInstanceUtils();